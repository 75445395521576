import React from "react";
import { SEO, PageLayout } from "@bluefin/components";
import { Image, Grid, a, h5 } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class PtlPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"ptl-page"} hero={null}>
          <div>
            <Image
              size={"massive"}
              centered={true}
              src={
                "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/PTL_YwC6nMB.jpeg"
              }
            />
          </div>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            centered={true}
          >
            <Grid.Column width={14}>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                textAlign={"left"}
                centered={true}
                column={"2"}
              >
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://www.cbsnews.com/pittsburgh/video/celina-pompeani-mathison-joins-us-from-izzazu-salon-spa-and-blowout-bar/?intcid=CNM-00-10abd1h"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/celina1.png"
                      }
                    />
                  </a>
                  <h5>
                    Celina Pompeani Mathison joins us from Izzazu Salon, Spa and
                    Blowout Bar
                  </h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://www.cbsnews.com/pittsburgh/video/celina-around-town-izzazu-salon-spa-and-blowout-bar/?intcid=CNM-00-10abd1h"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/Celina2.png"
                      }
                    />
                  </a>
                  <h5>Izzazu Salon, Spa and Blowout Bar</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://www.cbsnews.com/pittsburgh/video/trendiest-party-and-wedding-looks-from-izzazu-salon-spa-and-blowout-bar/?intcid=CNM-00-10abd1h"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/ptl3.png"
                      }
                    />
                  </a>
                  <h5>
                    Trendiest party and wedding looks from Izzazu Salon, Spa and
                    Blowout Bar
                  </h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://www.cbsnews.com/pittsburgh/video/spring-is-in-the-hair-izzazu-salon-spa-and-blowout-bar/?intcid=CNM-00-10abd1h"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/ptl4.png"
                      }
                    />
                  </a>
                  <h5>
                    Spring is in the Hair: Izzazu Salon, Spa and Blowout Bar
                  </h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://www.cbsnews.com/pittsburgh/video/pamper-mom-at-izzazu-salon-spa-and-blowout-bar/?intcid=CNM-00-10abd1h"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/ptl5.png"
                      }
                    />
                  </a>
                  <h5>Pamper mom at Izzazu Salon, Spa and Blowout Bar</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://www.cbsnews.com/pittsburgh/video/izzazu-salon-styling-pink-hair-for-breast-cancer-awareness-month/?intcid=CNM-00-10abd1h"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/pink.jpeg"
                      }
                    />
                  </a>
                  <h5>Pink Hair We Care</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://www.cbsnews.com/pittsburgh/video/izzazu-salon-providing-free-haircuts-to-firefighters-for-international-firefighters-day/#x"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/Screen_Shot_2022-05-05_at_1_10_17_PM.jpg"
                      }
                    />
                  </a>
                  <h5>Firefighter Appreciation Day</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/6224092-earth-day-fashion-show/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/eco_cDp9K59.jpeg"
                      }
                    />
                  </a>
                  <h5>Ecolution is Back!</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/6211209-going-bald-for-a-good-cause/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/shaving1.jpeg"
                      }
                    />
                  </a>
                  <h5>Shaving for a Cause</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/6195052-valentines-day-hair-trends/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/valentine.jpeg"
                      }
                    />
                  </a>
                  <h5>Valentines Day Looks</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/30SUj0g"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/nyepartylooks_KxPm7I8.jpeg"
                      }
                    />
                  </a>
                  <h5>New Years Party Looks</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/31bEUZ1"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/holidayparty.jpeg?width=500"
                      }
                    />
                  </a>
                  <h5>Holiday Party Ready</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/3C6sRsx"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/masterretires.jpeg?width=500"
                      }
                    />
                  </a>
                  <h5>The Master Retires</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/3gZk8kd"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/fallhair_ObbCZL4.jpeg?width=500"
                      }
                    />
                  </a>
                  <h5>Fall Hair Trends</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/3owckuV"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/pinkhair_9EItnPS.jpeg?width=500"
                      }
                    />
                  </a>
                  <h5>Pink Hair!</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/2XVTorz"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/safe_GVv13lH.jpeg?width=500"
                      }
                    />
                  </a>
                  <h5>Safely Reopening</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/4403180-fresh-new-look/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "  https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/newlooks_KGol9rT.jpeg"
                      }
                    />
                  </a>
                  <h5>Fresh New Looks</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/4403138-fresh-new-looks-women/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/freshlooks.jpeg?width=500"
                      }
                    />
                  </a>
                  <h5>Fresh Looks for Women</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/35sl0GI"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/pinkhairwecare.jpeg"
                      }
                    />
                  </a>
                  <h5>Pink Hair, We Care</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/2KSvRju"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/celebritystylists.jpeg"
                      }
                    />
                  </a>
                  <h5>
                    Celebrity Stylist, Jessica Bakis Talks about finding your
                    best look
                  </h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/2KIQIG4"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/back2school2.jpeg"
                      }
                    />
                  </a>
                  <h5>Back to School</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4093354-easy-frizzy-hair-hacks/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/back2school3.jpeg"
                      }
                    />
                  </a>
                  <h5>Back to School</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4093354-easy-frizzy-hair-hacks/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/fathersdaymakeovers.jpeg"
                      }
                    />
                  </a>
                  <h5>Father's Day Makeovers</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4093354-easy-frizzy-hair-hacks/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/frizz.jpeg"
                      }
                    />
                  </a>
                  <h5>Tame that Frizz</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4062379-ecolution-fashion-show/?fbclid=IwAR0ApRHrLLtSCSuNgHneBm8VqIsugwSymzuDI9GRqkwZkxMbLeF8_F5pxrw"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/mothersday.jpeg"
                      }
                    />
                  </a>
                  <h5>Mother's Day Makeover</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/4082478-mothers-day-makeover/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/mothersday2.jpeg"
                      }
                    />
                  </a>
                  <h5>Mother's Day Makeover</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4062379-ecolution-fashion-show/?fbclid=IwAR0ApRHrLLtSCSuNgHneBm8VqIsugwSymzuDI9GRqkwZkxMbLeF8_F5pxrw"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/eco2.jpeg"
                      }
                    />
                  </a>
                  <h5>Ecolution Fashion Show</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4053675-fresh-new-look-short-hair-style/?fbclid=IwAR1e2hNEvuo-XyGVWWZGFxFyaWjT_NpuZ-ccOu64FpNG5g22QzOc_Y0Iihk"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/shortsummer.jpeg"
                      }
                    />
                  </a>
                  <h5>Short Summer Styles</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4053687-fresh-new-look-style-color/?fbclid=IwAR2HmpHWDp4RJLnIbm7ERK4tEXUWqfFKF-iaXioZaF8ZyvtcUZxYHlQ8zII"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/summer.jpeg"
                      }
                    />
                  </a>
                  <h5>New looks for Summer</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/2SYhlZM"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/stbaldricks.jpeg"
                      }
                    />
                  </a>
                  <h5>St. Baldricks Head Shaving!</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={"https://cbsloc.al/2BGDx4y"}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/lights.jpeg"
                      }
                    />
                  </a>
                  <h5>Lights! Glamour! Action!</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4009194-izzuzus-stylists-offer-some-hair-style-inspirations-for-the-new-year/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/newyearnewhair.jpeg"
                      }
                    />
                  </a>
                  <h5>New Year, New Hair</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/program/750/4009194-izzuzus-stylists-offer-some-hair-style-inspirations-for-the-new-year/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/nyepartylooks.jpeg"
                      }
                    />
                  </a>
                  <h5>New Years Party Looks</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/3979207-izzazus-special-looks-for-holiday-events/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/holidayparty.jpeg"
                      }
                    />
                  </a>
                  <h5>Holiday Party Vibes</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/3979202-izzazus-winter-wedding-trends/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/ww.jpeg"
                      }
                    />
                  </a>
                  <h5>Winter Weddings</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/3916324-izzazus-cool-back-to-school-hairstyles/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/school1.jpeg"
                      }
                    />
                  </a>
                  <h5>Back-to-School</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/3852001-izzazus-scalp-care-tips/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/scalp.jpeg"
                      }
                    />
                  </a>
                  <h5>Facials for your Scalp?</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/3896078-izzazu-salons-summer-hair-care-tips/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/easysummer.jpeg"
                      }
                    />
                  </a>
                  <h5>Easy Summer Styles</h5>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <a
                    href={
                      "https://pittsburgh.cbslocal.com/video/category/arts-entertainment-live-performance/3878645-izzazu-salon-4-hot-looks-for-fathers-day/"
                    }
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                  >
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/78131329-8118-4c8a-ad48-3ed6a21b322b/hot.jpeg"
                      }
                    />
                  </a>
                  <h5>Hot Looks for Fathers Day</h5>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
